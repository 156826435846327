<template>
    <div class="modal_container sync_contact">
        <Form @submit="handleSubmit" v-slot="{ errors }" ref="sync-form">
            <div class="modal_header">
                <div class="modal_info">
                    <h1>Sync Contact</h1>
                    <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="form_grp col-3">
                        <div class="group_item">
                            <label class="input_label">Select Integration:</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.integration_type }">
                                <Field autocomplete="off" name="integration_type" v-model="form.type" :class="{ 'has-error': errors.integration_type }" rules="required" label="integration type">
                                    <multiselect
                                        v-model="form.type"
                                        :options="options"
                                        placeholder="Select Integration"
                                        value-prop="value"
                                        label="label"
                                        :searchable="true"
                                    ></multiselect>
                                </Field>
                            </div>
                            <ErrorMessage name="integration_type" class="text-danger" />
                        </div>
                    </div>
                    <div v-if="form.type === 1">
                        <div class="setting_wpr mt-3 text-center" v-if="user.mb_id">
                            <h3>Connected</h3>
                            <i>Your clients will be synced every night.</i>
                            <br /><br />
                        </div>
                        <div class="setting_wpr" v-else>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Studio ID:</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.studio_id }">
                                        <Field autocomplete="off" name="studio_id" type="text" v-model="form.mb_studio_id" placeholder="ex: 9999" rules="required" label="studio id" />
                                    </div>
                                    <ErrorMessage name="studio_id" class="text-danger" />
                                </div>
                            </div>
                        </div>
                        <img src="@/assets/images/mind-body.jpg" alt="" class="mind_body" v-if="!user.mb_id">
                        <p class="mt-2">
                            Mindbody charges a monthly fee of $12.95 to sync your contacts. This $12.95 monthly add-on fee will be billed at the same interval as your current billing.
                            <br /><br />
                            Example: if you are Basic Annual, your add-on fee to use sync will be billed for 12 months at $12.95 per month upfront.
                        </p>
                        <div v-if="user.mb_id" class="mt-3 action_wpr flex-center">
                            <button type="button" :disabled="mbSyncLoader" @click="handleMBSync" class="btn save_btn">{{ mbSyncLoader ? 'Syncing' : 'Sync Clients Now' }}</button>
                        </div>
                    </div>
                    <div v-if="form.type === 2" class="mt-3">
                        <img src="@/assets/images/zenplanner.jpg" alt="" class="w-100">
                        <p class="mt-2">Log in to your Zen Planner account In the menu toolbar (on the left side), click Setup. Go to the External services section and click on "Web Hooks". <br/> <br/>Go <a href="https://new.onboardme.io/zen/planner">here</a>.Enter the following key in the "Account ID" Field</p>
                        <div class="setting_wpr">
                            <div class="form_grp">
                                <div class="group_item">
                                    <div class="field_wpr">
                                        <input type="text" :value="user.access_token" placeholder="API Key">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <div class="action_wpr">
                    <button class="btn save_btn" v-if="form.type === 1 && !user.mb_id"><i class="fa fa-spinner fa-spin" v-if="mbSyncLoader"></i> {{ mbSyncLoader ? 'Connecting' : 'Connect' }}</button>
                    <button class="btn cancel_btn" type="button" @click="closeModal()">Cancel</button>
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import axios from '@/services/axios'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    export default {
        name: 'Sync Contact',

        data () {
            return {
                options: [
                    { value: 1, label: 'Mind Body' },
                    // { value: 2, label: 'Zen Planner' },
                ],
                form: {
                    type: '',
                    mb_studio_id: '',
                },
                mbSyncLoader: false,
                billingApi: process.env.VUE_APP_BILLING_API_URL,
            };
        },

        props: {
            closeModal: Function,
            addContactWatcher: Number,
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            addContactWatcher () {
                const vm = this;

                vm.resetForm();
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            plansAndAddons: state => state.billingModule.plans,
            subscription: state => state.billingModule.subscription,
        }),

        mounted () {
            const vm = this;

            if (!vm.subscription.id) {
                vm.getSubscription();
            }

            vm.getPlans();
        },

        methods: {
            ...mapActions({
                syncMBClient: 'contactModule/syncMBClient',
                integrateMBSync: 'contactModule/integrateMBSync',
                refreshAuth: 'authModule/refreshAuth',
                getSubscription: 'billingModule/getSubscription',
                getPlans: 'billingModule/getPlans',
            }),

            resetForm () {
                const vm = this;

                vm.form = { type: '', mb_studio_id: '' };
            },

            handleSubmit () {
                const vm = this;

                if (vm.form.type == 1) {
                    vm.mbSyncLoader = true;

                    if (vm.subscription.has_mindbody || !vm.user.stripe_id) {
                        vm.integrateMBSync({ studio_id: vm.form.mb_studio_id }).then((resp) => {
                            vm.loader = false;
                            vm.closeModal();
                        }).catch((err) => {
                            vm.loader = false;
                            Toastr.handleServerError(err);
                        });
                    } else {
                        vm.billingConfirmation();
                    }
                }
            },

            handleMBSync () {
                const vm = this;
                const options = Helper.swalConfirmOptions('', 'Would you like to merge the existing records from your MindBody contacts?');
                options.cancelButtonText = 'No';

                vm.mbSyncLoader = true;

                Swal.fire(options).then((result) => {
                    vm.syncMBClient({ is_merge: result.isConfirmed }).then((resp) => {
                        if (resp.data.status == 1) {
                            const options = Helper.swalWarningOptions('Success!', 'Your request has been queued. Importing process may take up to 5-10 mins.');
                            Swal.fire(options);
                        }

                        vm.closeModal();
                        vm.mbSyncLoader = false;
                    }).catch((err) => {
                        vm.mbSyncLoader = false;
                        Toastr.handleServerError(err);
                    });
                });
            },

            billingConfirmation () {
                const vm = this;
                const addonPlan = vm.plansAndAddons.addons.month.filter((plan) => plan.nickname == 'mindbody')[0];
                const options = Helper.swalConfirmOptions('Are you sure?', `$${Math.round((parseFloat(addonPlan.amount) + Number.EPSILON) * 100) / 100} will be charged now and will be added to your next billing. Would you like to proceed?`, 'PROCEED');
                options.preConfirm = function () {
                                          return new Promise(function(resolve, reject) {
                                              axios.post(`${vm.billingApi}/stripe/subscription/increment-quantity`, { plan: addonPlan.price_id }, {
                                                  headers: {
                                                      Accept: 'application/json',
                                                      Authorization: `Bearer ${vm.user.access_token}`,
                                                  },
                                              }).then((resp) => {
                                                  vm.refreshAuth();

                                                  vm.integrateMBSync({ studio_id: vm.form.mb_studio_id }).then((resp) => {
                                                      vm.loader = false;
                                                      vm.closeModal();
                                                  }).catch((err) => {
                                                      vm.loader = false;
                                                      Toastr.handleServerError(err);
                                                  });

                                                  resolve(true);
                                              }).catch((err) => {
                                                  Toastr.error(err.response.data.error);
                                                  resolve(true);
                                              });
                                          });
                                      }

                Swal.fire(options).then((result) => {
                    if (result.isDismissed) {
                        vm.loader = false;
                    }
                });
            },

        },
    }
</script>

